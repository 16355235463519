@import url(https://fonts.googleapis.com/css?family=Alata|PT+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Alata', sans-serif;
  background: rgb(163, 163, 163);
  background: -webkit-gradient(linear, left top, right top, from(rgba(163, 163, 163, 1)), color-stop(77%, rgba(165, 204, 255, 1)), to(rgba(135, 179, 255, 1)));
  background: linear-gradient(90deg, rgba(163, 163, 163, 1) 0%, rgba(165, 204, 255, 1) 77%, rgba(135, 179, 255, 1) 100%);
}

button {
  font-family: 'Alata', sans-serif;
}


.alata {
  font-family: 'Alata', sans-serif;
}

.mono {
  font-family: "PT Mono";
}
.greeting{font-size:50px;margin-left:15%;margin-bottom:20px;padding:0px}

