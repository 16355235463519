@import url('https://fonts.googleapis.com/css?family=Alata|PT+Mono&display=swap');

body {
  font-family: 'Alata', sans-serif;
  background: rgb(163, 163, 163);
  background: linear-gradient(90deg, rgba(163, 163, 163, 1) 0%, rgba(165, 204, 255, 1) 77%, rgba(135, 179, 255, 1) 100%);
}

button {
  font-family: 'Alata', sans-serif;
}


.alata {
  font-family: 'Alata', sans-serif;
}

.mono {
  font-family: "PT Mono";
}